my-app { padding: 0 !important; }

.k-drawer-container {
    position: fixed;
    width: 100%;
    height: 100%;
}
.title {
    font-size: 18px;
    margin: 0;
}
.box {
    padding: 4px; 
    text-align: center; 
    vertical-align: center; 
}

.header { background-color: #fae47a; }
.nav { background-color: #fbd17f; }
.inner-content { background-color: #f5bf8b; }
.toc { background-color: #ebaa79; }
.footer { background-color: #e39a6a; }

.grid-layout-wrapper {
    width: 300px;
    margin: 0 auto;
    box-shadow: 0px 10px 20px #00000029;
    border-radius: 30px;
}
.grid-layout-page { padding: 20px 10px; }

.grid-layout-content {
    background: #F9F9F9;
    height: 470px;
    overflow: auto;
}

.grid-box { 
    padding: 4px;
    background-color: #87dfce;
}

.big { background-color: #fea69c; }
.rect { background-color: #fee47c; }

.arrow {
    padding: 8px 0 5px 238px;
}
.preference-section {
    max-width: none;
    /* width: 640px; */
}

.preference-section .k-listbox {
    width: 100%;
    height: 475px;
}

listbox-actions-right .k-listbox-actions {
    flex-direction: column;
}

.k-listbox .k-listbox-actions {
    display: flex;
    gap: 8px;
    flex-direction: column;
}

/* notification css */
.k-notification .k-notification-status,
.k-notification .k-notification-content {
    float: left;
}

/* move google captcha to left */
.grecaptcha-badge {
    width: 70px !important;
    overflow: hidden !important;
    transition: all 0.3s ease !important;
    left: 4px !important;
  }
  .grecaptcha-badge:hover {
    width: 256px !important;
  }

  .gear-menu .k-menu:not(.k-context-menu){
    background: none;
    position: absolute;
    right: 20px;
    padding: 0;
  }

  .k-pager-info{
    display: inherit !important;
  }
  
  